import {ClientType} from "../../../nomenclatures/clients/hooks/useClients";
import {Col, Form, Row} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import CheckIconButton from "../../../../common/icon-buttons/CheckIconButton";
import {ClientsDialog} from "../../../nomenclatures/clients/ClientsDialog";
import {FC, useEffect, useState} from "react";
import I18Label from "../../../../i18/i18label";
import {dateWithEndDayTime, dateWithZeroTime} from "../../../../common";

export type CRMSummaryReportFilterType = {
    fromDate: Date | null;
    toDate: Date | null;
    client: ClientType | null;
}

interface CRMSummaryReportFilterProps {
    onChange: (filter: CRMSummaryReportFilterType) => void;
}

const CRMSummaryReportFilter: FC<CRMSummaryReportFilterProps> = ({onChange}) => {
    const firstDayInMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0,0, 0, 0);
    const today = new Date();
    const clientsDialog = <ClientsDialog show={true} zIndex={1060} onClose={() => {}}/>
    const [filter, setFilter] = useState<CRMSummaryReportFilterType>({fromDate: firstDayInMonth, toDate: today, client: null});

    function applyFilter() {
        onChange(filter);
    }

    useEffect(() => {
        onChange(filter);
    }, []);

    return (
        <Row>
            <Col xs={"auto"}>
                <Form.Group className={"w-120px"}>
                    <Form.Label className={"small"}><I18Label label={"Начална дата"} /></Form.Label>
                    <ReactDatePicker className={"form-control"} isClearable
                                     dateFormat="dd.MM.yyyy"
                                     onChange={d => setFilter({
                                            ...filter,
                                            fromDate: dateWithZeroTime(d) ?? null
                                     })}
                                     selected={filter.fromDate}
                    />
                </Form.Group>
            </Col>
            <Col xs={"auto"}>
                <Form.Group className={"w-120px"}>
                    <Form.Label className={"small"}><I18Label label={"Крайна дата"} /></Form.Label>
                    <ReactDatePicker className={"form-control"} isClearable
                                     dateFormat="dd.MM.yyyy"
                                     onChange={
                                            d => setFilter({
                                                ...filter,
                                                toDate: dateWithEndDayTime(d) ?? null
                                            })
                                     }
                                     selected={filter.toDate}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className={"w-100"}>
                    <Form.Label><I18Label label={"Клиент"} />:</Form.Label>
                    <SelectValueFromDialog value={filter.client?.name || ''}
                                           onChange={(client) => {
                                               setFilter(prevState => {
                                                   return {...prevState, client: client}
                                               })
                                           }}
                                           dialog={clientsDialog}/>
                </Form.Group>
            </Col>
            <Col xs={"auto"}>
                <div style={{paddingTop: "30px"}}>
                    <CheckIconButton onClick={applyFilter}/>
                </div>
            </Col>
        </Row>
    );
}

export default CRMSummaryReportFilter;
