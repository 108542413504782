import {Button, Col, ModalBody, ModalFooter, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getDocs, getFirestore, query, updateDoc, where} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {WarehouseObjectsType, WarehouseObjectType} from "../../warehouse/hooks/useWarehouseObjects";
import useOwnCompanies, {OwnCompanyType} from "../../warehouse/hooks/useOwnCompanies";

export interface OwnCompaniesListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: OwnCompanyType) => any;
    onDelete: (item: OwnCompanyType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function OwnCompaniesList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: OwnCompaniesListProps) {
    const [ownCompanies, loadingOwnCompanies, errorOwnCompanies] = useOwnCompanies();
    // const [working, setWorking] = useState(false);

    // useEffect(() => {
    //         console.log(ownCompanies);
    //         if (ownCompanies && ownCompanies.length > 0) {
    //             setWorking(true);
    //
    //             getDocs(
    //                 query(
    //                     collection(fbDb, "customerInquiries"),
    //                     where("ownCompany.id", "not-in", ownCompanies.map(c => c.id))
    //                 )
    //             ).then(
    //                 (snapshot) => {
    //                     snapshot.forEach(async (doc) => {
    //                         const existingCompany = ownCompanies.find(c => c.name === doc.data().ownCompany.name);
    //                         if (existingCompany) {
    //                             console.log("existing company", existingCompany);
    //                             await updateDoc(doc.ref, {
    //                                 ownCompany: existingCompany
    //                             })
    //                         } else {
    //                             console.log("not found", doc.data().ownCompany.name);
    //                             await updateDoc(doc.ref, {
    //                                 ownCompany: ownCompanies[0]
    //                             });
    //                         }
    //                     });
    //                     setWorking(false);
    //                 }
    //             );
    //         }
    //     },[ownCompanies]);

    // if (working) {
    //     return (
    //         <Row>
    //             <Col className={"text-center"}>
    //                 <Spinner animation={"border"}/>
    //             </Col>
    //         </Row>
    //     );
    // }

    return (
        <>
            {loadingOwnCompanies && <Spinner animation={"border"}/>}

            {
                !loadingOwnCompanies && errorOwnCompanies && <LoadDataFromServerError/>
            }

            {
                !loadingOwnCompanies && !errorOwnCompanies && isVisible && ownCompanies && ownCompanies.length === 0 &&
                <Row className={"m-5"}>
                    <Col className={"text-center fw-bold"}>Няма дефинирани собствени фирми</Col>
                </Row>
            }


            {
                !loadingOwnCompanies && !errorOwnCompanies && ownCompanies && isVisible && ownCompanies.length > 0 &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    {/*<th style={{width: onSelect ? 120 : 90}}></th>*/}
                                    <th>Фирма</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    ownCompanies.length > 0 && ownCompanies.map(
                                        d =>
                                            <tr key={d.id} className={"tr-bordered"}>
                                                {/*<td>*/}
                                                {/*    <Button size={"sm"} variant={"outline-secondary"}*/}
                                                {/*            onClick={() => onEdit(d)}*/}
                                                {/*    >*/}
                                                {/*        <FontAwesomeIcon icon={faEdit}/>*/}
                                                {/*    </Button>&nbsp;*/}
                                                {/*    <Button size={"sm"} variant={"outline-danger"}*/}
                                                {/*            onClick={() => {*/}
                                                {/*                onDelete(d)*/}
                                                {/*            }}*/}
                                                {/*    >*/}
                                                {/*        <FontAwesomeIcon icon={faTrash}/>*/}
                                                {/*    </Button>&nbsp;*/}
                                                {/*    {*/}
                                                {/*        onSelect &&*/}
                                                {/*        <Button size={"sm"} variant={"outline-success"}*/}
                                                {/*                onClick={() => {*/}
                                                {/*                    onSelect(d)*/}
                                                {/*                }}*/}
                                                {/*        >*/}
                                                {/*            <FontAwesomeIcon icon={faCircleArrowDown}/>*/}
                                                {/*        </Button>*/}
                                                {/*    }*/}
                                                {/*</td>*/}
                                                <td>
                                                    {d.name}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>
                </>
            }

            {
                isVisible &&
                <ModalFooter>
                    {/*<Button onClick={() => onAdd()}>*/}
                    {/*    <FontAwesomeIcon icon={faPlus}/> Добави нова*/}
                    {/*</Button>*/}
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> Затвори
                    </Button>
                </ModalFooter>
            }
        </>
    )
}
